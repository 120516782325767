.report{    
    position: absolute;
    left: -9999px;
    width: 842px;
    height: 595px;
    margin-left: auto;
    margin-right: auto;
}
.reportButtom{
    background-color: #00acc1; /* Green */
    border: none;
    border-radius: 5px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: lighter;
    line-height:1.5em ;
}
.reportButtom:hover{
    background-color: #009eb3;
}